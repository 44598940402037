<template>
	<div class="convention-hall page">
		<van-nav-bar class="nav-bar" title="Đào Việt VIP" />
		<div class="convention-item">
			<van-tabs animated sticky line-width="100px" :swipeable="true">
				<!-- <van-tab title="Tỉnh / Thành Phố">
					<div class="card">Lưu ý: Nền tảng ứng dụng hẹn hò thông qua trực tuyến. Để đảm bảo quyền riêng tư cá nhân của người dùng, chỉ có thể tham gia với tư cách thành viên bằng cách liên hệ với chuyên viên để được sắp xếp lịch hẹn hò thành công sớm nhất.</div>
					<div class="address">
						<van-row type="flex" justify="center" gutter="20">
							<van-col span="12" offset="" v-for="(val, key) in addlist" :key="key">
								<div class="text-center linear-gradient mb-1 border-15 color-white" @click="addgo(v)" v-for="(v, k) in val" :key="k">{{ v.name }}</div>
							</van-col>
						</van-row>
					</div>
				</van-tab> -->
				<van-tab title="Giới thiệu nền tảng">
					<div class="card">Lưu ý: Nền tảng ứng dụng hẹn hò thông qua trực tuyến. Để đảm bảo quyền riêng tư cá nhân của người dùng, chỉ có thể tham gia với tư cách thành viên bằng cách liên hệ với chuyên viên để được sắp xếp lịch hẹn hò thành công sớm nhất.</div>
					<div class="rig-box">
						<p class="rig-title">Có những nguồn tài nguyên nào?</p>
						<p class="rig-content">Người nổi tiếng, diễn viên, học sinh, sinh viên, những cô gái trẻ tuổi, mc,giáo viên... nền tảng chúng tôi sẽ hỗ trợ sắp xếp cho các bạn cách liên lạc gặp mặt, hẹn hò và vui vẻ cùng nhau, nhưng có điều bạn không thể liên hệ được nếu không thông qua app hẹn hò này.</p>
						<p class="rig-title">Quy mô của APP?</p>
						<p class="rig-content">Hẹn hò miễn phí trong cùng một thành phố, tất cả các địa điểm trên toàn quốc, có mặt tại các thành phố hàng đầu trong nước, cũng có thể sắp xếp thông qua việc liên hệ với nhân viên tiếp đãi.</p>
						<p class="rig-content">Trang web này cung cấp các dịch vụ liên quan đến ngoại vi, đồng hành kinh doanh và cảm xúc trong cùng một thành phố. Để bảo vệ quyền riêng tư của từng người dùng, khách hàng chỉ được tham gia thông qua việc liên hệ với nhân viên tiếp đãi hoặc thành viên cấp cao của nền tảng này.</p>
					</div>
				</van-tab>
			</van-tabs>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			addlist: [
				// {
				// 	0: '北京',
				// 	1: '上海',
				// 	2: '广州',
				// 	3: '深圳'
				// },
				// {
				// 	0: '南京',
				// 	1: '长沙',
				// 	2: '重庆',
				// 	3: '西安'
				// }
			]
		};
	},
	methods: {
		addgo(data) {
			if (!localStorage.getItem('token')) {
				this.$router.push({ path: '/Login' });
			} else {
				this.$router.push({ path: '/list?id=' + data.id + '&name=' + data.name });
			}
		},
		getAddress() {
			this.$http({
				method: 'get',
				url: 'address_list'
			}).then(res => {
				this.addlist = res.data;
			});
		}
	},
	created() {
		this.getAddress();
	}
};
</script>

<style lang="less" scoped>
.page {
	position: absolute !important;
	top: 0;
	left: 0;
	right: 0;
	background-color: #f2f2f5;
}
.nav-bar {
	// background: linear-gradient(90deg, #ac6015, #e6c3a1);
	height: 100px;
}
.van-nav-bar {
	line-height: 50px;
}
::v-deep .van-nav-bar__title {
	max-width: 60%;
	margin: 0 auto;
	color: #ffffff;
	font-size: 35px;
}
::v-deep .van-nav-bar__content {
	height: 100px;
}
.van-sidebar {
	width: 180px;
}
.convention-hall {
	display: flex;
	flex-direction: column;
	bottom: 20px;
	background: #f2f2f5;
}

.linear-gradient {
	background: linear-gradient(270deg,#987018, #98704a);
}

.text-center {
	text-align: center;
}

.mb-1 {
	margin-bottom: 1rem;
}

.border-15{
	border-radius: 15px;
}

.color-white {
	color: #FFF !important;
}

::v-deep .van-tab {
	font-size: 30px;
	line-height: 100px;
	font-weight: bold;
}

::v-deep .van-tabs__content--animated {
	overflow: unset;
}

::v-deep .van-tabs__line {
	background-color: #ac6015;
}
::v-deep .van-tabs--line .van-tabs__wrap {
	height: 100px;
}
::v-deep .van-tabs__wrap--scrollable .van-tab {
	padding: 0 23px;
}
.card {
	background-color: #8a637d;
	padding: 0.625rem;
	width: 95%;
	color: white;
	margin: 0.625rem auto;
	border-radius: 0.375rem;
}
::v-deep .van-row--flex {
	height: 80px;
	line-height: 80px;
}
.rig-box {
	width: 95%;
	margin: 0.625rem auto;
}
.rig-title {
	color: #0bdab0;
	font-size: 1.125rem;
}
.rig-content {
	font-size: 20px;
	// margin-top: 10px;
}
.address {
	width: 90%;
	margin: 0 auto;
}
</style>
